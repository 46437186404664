














import _ from 'lodash';
import Vue from 'vue';
import TopBg from '../fair/component/detailTopBg.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  name: 'ProxyExh',
  layout: 'layout2',
  components: { TopBg },
  async asyncData(ctx) {
    const id = _.get(ctx, 'params.id', null);
    const res: any = await ctx.$axios.get(`/api/exhibition/${id}`);

    let fair = {};
    if (res.code === ResponseCode.SUCCESS) {
      fair = res.data || {};
    }
    return {
      fairId: id,
      detail: fair,
    };
  },
  data(): any {
    return {
      detail: {},
    };
  },
  head(): Record<string, any> {
    const {
      startTime = '',
      endTime = '',
      exhibitionName = '',
      industry = '',
      organizer = '',
      pavilionInfo = {},
    } = this.$data.detail;
    const {
      areaName = '',
      regionName = '',
      pavilionName = '',
    } = (pavilionInfo || {}) as Record<string, any>;
    const industrys = industry?.split(',');
    const industryName = _.get(industrys, '[0]', '');
    return {
      title: `${startTime.slice(0, 4)}${exhibitionName}_代参展-聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${areaName}${industryName}展,${regionName}${industryName}展`,
        },
        {
          name: 'description',
          content: `展会时间:${startTime}至${endTime},展会地点:${pavilionName},主办单位:${organizer},聚展网提供展位预订,展会门票,展会行程,展会搭建等服务`,
        },
      ],
    };
  },
});
