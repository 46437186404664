




import Vue from 'vue';
import _ from 'lodash';
import Invoice from './component/invoice.vue';

export default Vue.extend({
  name: 'Periodical',
  components: {
    Invoice,
  },
  filters: {},
  async asyncData(ctx) {
    // 301重定向-重定向到新url
    let newUrl: any = `/periodical/${ctx.params.id}.html`;
    ctx.redirect('301', newUrl);
    return {};
  },
  data(): Record<string, any> {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {},
  head(): Record<string, any> {
    return {};
  },
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
});
