













































































import Vue from 'vue';
import _ from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';
import { $cookies } from '~/util/api';

export default Vue.extend({
  name: 'Periodical',
  components: {},
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${ctx.params.id}`),
      ctx.$axios.get(`/api/periodical/periodicalList/${ctx.params.id}`),
    ]);
    const [fairDetailRes, periodicalRes] = res;

    let fairDetail: Record<string, any> = {};
    const industry: any[] = [];
    let industryId: any[] = [];
    let industryName: any[] = [];
    if (fairDetailRes.code === ResponseCode.SUCCESS) {
      fairDetail = fairDetailRes.data;
      industryId = [
        fairDetail.industry1,
        fairDetail.industry2,
        fairDetail.industry3,
      ].filter((id) => !!id);
      industryName = fairDetail.industry?.split(',') || [];
      industryName?.forEach((name, index) => {
        industry.push({
          id: industryId[index],
          name,
        });
      });
    }

    let periodicalList = [];
    if (periodicalRes.code === ResponseCode.SUCCESS) {
      periodicalList = periodicalRes.data;
    }

    const fairRes: any = await ctx.$axios.post('/api/exhibition/list', {
      locale: langApi,
      selectTimeType: 1,
      industryId: _.get(industry, '[0].id', null), // 行业展会
      regionType: fairDetail.regionType,
      pageNum: 1,
      pageSize: 6,
    });
    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data;
    }

    return {
      fairDetail,
      fairId: ctx.params.id,
      fair,
      periodicalList,
    };
  },
  data() {
    return {
      submitDisabled: false,
      ruleForm: {},
      rules: {
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        concatTel: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
        ],
        concatMail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    if (process.client) {
      const access_token = $cookies.get('access_token');
      if (access_token) {
        this.queryInfo();
      }

      const ruleForm = sessionStorage.getItem('applyPdfForm') || '{}';
      try {
        this.ruleForm = JSON.parse(ruleForm);
        sessionStorage.removeItem('applyPdfForm');
      } catch (error) {}
    }
  },
  methods: {
    shouldLogin(): boolean {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(
        //   `/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
        // );
        sessionStorage.setItem('applyPdfForm', JSON.stringify(this.ruleForm));
      }
      return !!access_token;
    },
    async showMessage() {
      const { exhibitionName } = this.$data.fairDetail;

      await message.call(this, {
        delay: 3000,
        type: 'warning',
        title: '温馨提示',
        msg: `尊敬的用户，您已订购${exhibitionName}电子会刊，我们将在第一时间通知您最新的会刊信息。`,
      });
    },
    async queryInfo() {
      const { userId } = this.$store.state.global.user;

      const params = {
        exhibitionId: this.$data.fairId,
        userId,
      };
      const res: any = await this.$axios.post(
        '/api/periodical/orderInfoQuery',
        params
      );

      if (res.code === ResponseCode.SUCCESS) {
        if (res.data === '已申请') {
          this.submitDisabled = true;
          this.showMessage();
        }
      }
    },
    async submitData() {
      const { userId } = this.$store.state.global.user;
      const { exhibitionName } = this.$data.fairDetail;

      const params: Record<string, any> = {
        exhibitionId: this.$data.fairId,
        exhibitionName,
        userId,
        osType: '2', // 1:h5  2:PC  3:小程序
        ...this.ruleForm,
      };

      this.submitDisabled = true;
      const res: any = await this.$axios.post(
        '/api/periodical/applyFor',
        params
      );

      if (res.code === ResponseCode.SUCCESS) {
        const $orderform = this.$refs.orderform as ElForm;
        const { fairDetail } = this.$data;

        $orderform?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '会刊申请成功',
          msg: `尊敬的用户，您订购的${fairDetail.exhibitionName}电子会刊还未更新，我们将在第一时间通知您最新的会刊信息。`,
        });
      } else {
        this.submitDisabled = false;
        await message.call(this, {
          delay: 3000,
          type: 'error',
          title: '会刊申请失败',
          msg: '请联系客服处理。',
        });
      }
    },
    handleSubmit() {
      if (this.submitDisabled) {
        this.showMessage();
        return;
      }
      const $orderform = this.$refs.orderform as ElForm;
      $orderform.validate((valid) => {
        if (valid) {
          if (this.shouldLogin()) {
            this.submitData();
          }
        }
      });
    },
  },
  head(): Record<string, any> {
    const { fairDetail } = this.$data;
    return {
      title: `${fairDetail.exhibitionName}_展会期刊-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
});
